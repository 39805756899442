import {
  SET_CHILD_USER_INFO,
  UPDATE_CHILD_USER_INFO,
  UPDATE_CHILD_USER_TU_INFO,
} from "./types";

const initialState = { setApiHit: false };

export const userChildInfoReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case SET_CHILD_USER_INFO:
      return { ...state, ...payload };
    case UPDATE_CHILD_USER_INFO:
      return { ...payload };
    default:
      return state;
  }
};
