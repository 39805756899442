
 export const URL_CONFIG = {
     API_URL: 'https://api-dev.arya.ag/api/',
     PROD_BASE_PATH: 'https://api-uat.arya.ag/image/',
     PROD_DOMAIN: 'https://uat3.arya.ag/',
     API_bd_URL: 'https://api-uat.arya.ag/bd/',
    
    WH_RAZORPAY_KEY: 'xxxxxxxxxxxxx',
    WH_RAZORPAY_SECRET_KEY: 'xxxxxxxxxxxxx',
    PLAN_RAZORPAY_KEY: 'xxxxxxxxxxxxx',
    PLAN_RAZORPAY_SECRET_KEY: 'xxxxxxxxxxxxx',
    GOOGLE_API_KEY : "AIzaSyAGONI6_PRCWSKosjo_q1q4VET-Cqy52f8",
  FIREBASE_API_KEY : "AIzaSyBcWlYe02ix7u-F9zTXTl193ABwGtxROiE",
  GEOCODE_API_KEY : "AIzaSyBLTWd9NlDnXLkyEhGZpTrGp7w9yxISuAw",


    PAY_LOAN_RAZORPAY_KEY: 'xxxxxxxxxxxxx',
    PAY_LOAN_RAZORPAY_SECRET_KEY: 'xxxxxxxxxxxxx',



    STOCK_RAZORPAY_KEY: 'xxxxxxxxxxxxx',
    STOCK_RAZORPAY_SECRET_KEY: '',
    
       RELEASE_STOCK: 'xxxxxxxxxxxxx',
    RELEASE_SECRET: 'xxxxxxxxxxxxx',

    PROD_DOMAIN_IMAGES: window.location.origin + '/assets/Images/',
    MARKETPLACE_URL: 'https://market-uat.arya.ag/en',
    MARKET_LOGIN_URL: 'https://market-uat.arya.ag/en/user/login',
    SINGLE_SIGNIN:true,
    SELL_COMMODITY:"https://market-uat.arya.ag/en/browse/buy-commodity",
    RMS_URL: 'https://rms-uat.arya.ag?',
     ARYA_LOGOUT:'https://market-uat.arya.ag/en/?actionLogout=1',
       ENVIROEMENT:'UAT',
         ORIGIN_URL:'https://uat3.arya.ag',
        WHATS_APP: '919289312347',
 IMG_URL: "https://uat.arya.ag/assets/Images/",
    SKIPPABLE_BANK_ACCOUNT:'135791197531'
 }
